:root {
    --primary: #F9F9F9;
    --secondary: #E0DCD2;
    --color1:rgb(251,251,251);
    --color2:#E8EDE6;
    --text:rgb(96, 139, 106);
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%; 
    position: fixed;
    z-index: -1; 
}

.hero-container {
    /* background: url('../../public/images/flavors2.png'); */
    background-size: 100% 100%;
    background-position: 80% 115%; /* Adjust the horizontal position */
    /* background-color: var(--color2); */
    height: 60vh; 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2); */
}

.flavor_bg_image {
    /* background-position: 80% 80%; */
    background-size: 70% 80%;


}

.text-wrap {
    width: 80%;
    margin-top: 40px;
    color: var(--text);

}
.text-wrap  > h1 {
    text-align: start;
}
/* .hero-container > h1 {
    color: var(--text);
    font-size: 100px;
    margin-top: -100px;
}

.hero-container > p {
    margin-top: 8px;
    color: var(--text);
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
} */

.hero-btns {
    /* margin-top: 50px; */
    display: flex;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px){
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
    
}

@media screen and (max-width: 768px){
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }
    .hero-container > p {
        font-size: 30px;
    }
    .btn-mobile {
        display: block;
        text-decoration: none;
    }
    .btn {
        width: 100%;
    }
    
}