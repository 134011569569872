:root {
    --primary: #ffff;
    --secondary: #242424;
    --text:rgb(68, 107, 77);
}

.btn {
    padding: 8px 40px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    
}



.btn--primary {
    background-color: var(--primary);
    color: var(--text);
    border: 1px solid var(--primary);
   
}

.btn--outline {
    background-color: transparent;
    color: var(--text);
    padding: 8px 40px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 40px;
    font-size: 20px;
}


.btn--medium:hover, .btn--large:hover {
    background: var(--secondary);
    color: var(--text);
    transition: all 0.3s ease-out;
}