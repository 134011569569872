* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: 'PT Sans', sans-serif; */
    font-family: "Raleway", sans-serif;
  }
  :root {
    --primary: #F9F9F9;
    --secondary: #E0DCD2;
    --color1:rgb(251,251,251);
    --color2:#E8EDE6;
    --text:rgb(68, 107, 77);
    --font: "Raleway", sans-serif;
}

h1{
  color: var(--text);
  font-family: var(--font);
}

  .home,
  .flavors,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
/*   
  .flavors {
    background-image: url('../public/images/flavors.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  } */
  
  .products {
    /* background-image: url('/images/img-1.jpg'); */
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    /* background-image: url('/images/img-8.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }